import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#E1A87E',
      selected: '#969696'
    },
    secondary: {
      main: '#969696',
      dark: '#3c3c3c'
    }
  },
  typography: {
    fontFamily: ['Lexend', 'sans-serif'].join(',')
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 16,
        fontWeight: 700,
        lineHeight: 1.3,
        letterSpacing: 0
      },
      text: {
        borderRadius: 2
      },
      textSizeSmall: {
        padding: '4px 12px'
      },
      containedPrimary: {
        color: '#fff'
      },
      containedSecondary: {
        color: '#fff',
        backgroundColor: '#696969',
        '&:hover': {
          backgroundColor: '#444',
          boxShadow: '0 0 10px #ddd'
        }
      },
      outlinedSecondary: {
        '&:hover': {
          border: '1px solid #444',
          color: '#444',
          boxShadow: '0 0 10px #ddd'
        }
      }
    },
    MuiIconButton: {
      label: {
        fontFamily: "'Lexend', sans-serif"
      }
    },
    MuiMobileStepper: {
      progress: {
        width: '100%'
      }
    },
    MuiLinearProgress: {
      root: {
        height: 12
      }
    },
    MuiCheckbox: {
      root: {
        padding: '0 9px'
      }
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
        lineHeight: 1.2
      },
      sizeSmall: {
        padding: '6px 16px',
        '@media (max-width:600px)': {
          padding: 8
        }
      }
    },
    MuiTableRow: {
      root: {
        '&:hover': {
          background: 'rgb(255 164 0 / 15%)'
        }
      },
      head: {
        background: '#ddd',
        '&:hover': {
          background: '#ddd'
        }
      }
    }
  }
});

/*const UserProvider = ({children}) => {
  const loggedIn = sessionStorage.getItem('docs');
  let authObject;
  if (loggedIn) {
    authObject = {name: JSON.stringify(loggedIn), auth: true};
  } else {
    authObject = {name: '', auth: false};
  }
  const [user, setUser] = useState(authObject);

  const login = (name) => {
    setUser((user) => ({
      name: name,
      auth: true,
    }));
    sessionStorage.setItem('docs', name);
  };

  const logout = () => {
    setUser((user) => ({
      name: '',
      auth: false,
    }));
    sessionStorage.removeItem('docs');
    sessionStorage.removeItem('vsncode');
  };

  return <UserContext.Provider value={{user, login, logout}}>{children}</UserContext.Provider>;
};*/

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
