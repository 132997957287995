import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { TimePicker } from '@material-ui/pickers'


export default function TelemedicineSchedule(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState('');
  const {doctorList, refreshDoctorList, snackCallback} = props;
  var workingHrs = ( doctorList[0] !== undefined && doctorList[0].working_hrs !== undefined) ? JSON.parse(doctorList[0].working_hrs): '';
  workingHrs['Hours'] = workingHrs['Hours'][0];
  const [telemedicineFormValues, setTelemedicineFormValues] = useState({
    monday_start:{ 
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Monday !== undefined) ? workingHrs['Hours'].Monday[0].StartR: '',
      error:false,
      errorMessage:'You must enter a monday start time'
    },
    monday_end:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Monday !== undefined) ? workingHrs['Hours'].Monday[0].FinishR: '',
      error:false,
      errorMessage:'You must enter a monday end time'
    },
    tuesday_start:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Tuesday !== undefined) ? workingHrs['Hours'].Tuesday[0].StartR: '',
      error:false,
      errorMessage:'You must enter a tuesday start time'
    },
    tuesday_end:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Tuesday !== undefined) ? workingHrs['Hours'].Tuesday[0].FinishR: '',
      error:false,
      errorMessage:'You must enter a tuesday end time'
    },
    wednesday_start:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Wednesday !== undefined) ? workingHrs['Hours'].Wednesday[0].StartR: '',
      error:false,
      errorMessage:'You must enter a wednesday start time'
    },
    wednesday_end:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Wednesday !== undefined) ? workingHrs['Hours'].Wednesday[0].FinishR: '',
      error:false,
      errorMessage:'You must enter a wednesday end time'
    },
    thursday_start:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Thursday !== undefined) ? workingHrs['Hours'].Thursday[0].StartR: '',
      error:false,
      errorMessage:'You must enter a thursday start time'
    },
    thursday_end:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Thursday !== undefined) ? workingHrs['Hours'].Thursday[0].FinishR: '',
      error:false,
      errorMessage:'You must enter a thursday end time'
    },
    friday_start:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Friday !== undefined) ? workingHrs['Hours'].Friday[0].StartR: '',
      error:false,
      errorMessage:'You must enter a friday start time'
    },
    friday_end:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Friday !== undefined) ? workingHrs['Hours'].Friday[0].FinishR: '',
      error:false,
      errorMessage:'You must enter a friday end time'
    },
    saturday_start:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Saturday !== undefined) ? workingHrs['Hours'].Saturday[0].StartR: '',
      error:false,
      errorMessage:'You must enter a saturday start time'
    },
    saturday_end:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Saturday !== undefined) ? workingHrs['Hours'].Saturday[0].FinishR: '',
      error:false,
      errorMessage:'You must enter a saturday end time'
    },
    sunday_start:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Sunday !== undefined) ? workingHrs['Hours'].Sunday[0].StartR: '',
      error:false,
      errorMessage:'You must enter a sunday start time'
    },
    sunday_end:{
      value: ( workingHrs['Hours'] !== undefined && workingHrs['Hours'].Sunday !== undefined) ? workingHrs['Hours'].Sunday[0].FinishR: '',
      error:false,
      errorMessage:'You must enter a sunday end time'
    },
  });

  const telemedicineHandleChange = (key, value) => {
    const prname = key;
    telemedicineFormValues[prname].value = value;
    setTelemedicineFormValues({
      ...telemedicineFormValues,
      [prname]:{
        ...telemedicineFormValues[prname],
        value
      }
    });
  }

  const telemedicineHandleSubmit = (e) => {
    e.preventDefault();

    const tmformFields = Object.keys(telemedicineFormValues);
    let newTelemedicineFormValues = {...telemedicineFormValues}
    let isError = false;
    for (let index = 0; index < tmformFields.length; index++) {
      const tmcurrentField = tmformFields[index];
      const tmcurrentValue = telemedicineFormValues[tmcurrentField].value;

      if(tmcurrentValue === '' || tmcurrentValue === null){
        isError = true;
        newTelemedicineFormValues = {
          ...newTelemedicineFormValues,
          [tmcurrentField]:{
            ...newTelemedicineFormValues[tmcurrentField],
            error:true
          }
        }
      }
    }
    setTelemedicineFormValues(newTelemedicineFormValues);
    if(isError === false)
    {
      updateAvailability();
    }
  }

  const updateAvailability = () => {
    const api = `${process.env.REACT_APP_API_URL}/doctor/update-availability`;
    const axiosConfig = {
      method: 'POST',
      url: api,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {
        npi:sessionStorage.getItem('vsndocnpi'),
        monday_start: telemedicineFormValues.monday_start.value,
        monday_end: telemedicineFormValues.monday_end.value,
        tuesday_start: telemedicineFormValues.tuesday_start.value,
        tuesday_end: telemedicineFormValues.tuesday_end.value,
        wednesday_start: telemedicineFormValues.wednesday_start.value,
        wednesday_end: telemedicineFormValues.wednesday_end.value,
        thursday_start: telemedicineFormValues.thursday_start.value,
        thursday_end: telemedicineFormValues.thursday_end.value,
        friday_start: telemedicineFormValues.friday_start.value,
        friday_end: telemedicineFormValues.friday_end.value,
        saturday_start: telemedicineFormValues.saturday_start.value,
        saturday_end: telemedicineFormValues.saturday_end.value,
        sunday_start: telemedicineFormValues.sunday_start.value,
        sunday_end: telemedicineFormValues.sunday_end.value
      }
    };
    axios(axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          snackCallback('Saved successfully.', 'success');
          setErrors('');
          refreshDoctorList();
        } else {
          setErrors('There was a problem. Please try again or contact IT.');
        }
      })
      .catch((error) => {
        setErrors('There was a problem. Please try again or contact IT.');
      });
  };

  return (
  <Paper elevation={4}>
    <Box className={classes.jss366}>
      <Typography variant='h4' component='h4' className={classes.fontSizeSecondary}>
        Telemedicine Schedule
      </Typography>
      <Box className={classes.errorBox}>
        <small className={classes.error}>{errors}</small>
      </Box>
      <form noValidate onSubmit={telemedicineHandleSubmit}>
      <Grid container spacing={1} className={classes.marginTop}>
        
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
            
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
              Start
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
              End
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
            Monday
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
              <TimePicker
                className={[classes.fullwidth, classes.inputstyle]}
                id="outlined-monday-start"
                //label="Start"
                name="monday_start"
                variant="outlined"
                size="small"
                focused
                required
                value={telemedicineFormValues.monday_start.value}
                ampm={false}
                onChange={(value) => telemedicineHandleChange('monday_start', value)}
                renderInput={(params) => (
                  <TextField 
                    error={telemedicineFormValues.monday_start.error}
                    helperText={telemedicineFormValues.monday_start.error && telemedicineFormValues.monday_start.errorMessage}
                    {...params}
                  />
                )}
              />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-monday-end"
              //label="End"
              name="monday_end"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.monday_end.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('monday_end', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.monday_end.error}
                  helperText={telemedicineFormValues.monday_end.error && telemedicineFormValues.monday_end.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
            Tuesday
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-tuesday-start"
              //label="Start"
              name="tuesday_start"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.tuesday_start.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('tuesday_start', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.tuesday_start.error}
                  helperText={telemedicineFormValues.tuesday_start.error && telemedicineFormValues.tuesday_start.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-tuesday-end"
              //label="End"
              name="tuesday_end"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.tuesday_end.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('tuesday_end', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.tuesday_end.error}
                  helperText={telemedicineFormValues.tuesday_end.error && telemedicineFormValues.tuesday_end.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
            Wednesday
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-wednesday-start"
              //label="Start"
              name="wednesday_start"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.wednesday_start.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('wednesday_start', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.wednesday_start.error}
                  helperText={telemedicineFormValues.wednesday_start.error && telemedicineFormValues.wednesday_start.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-wednesday-end"
              //label="End"
              name="wednesday_end"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.wednesday_end.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('wednesday_end', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.wednesday_end.error}
                  helperText={telemedicineFormValues.wednesday_end.error && telemedicineFormValues.wednesday_end.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
            Thursday
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-thursday-start"
              //label="Start"
              name="thursday_start"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.thursday_start.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('thursday_start', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.thursday_start.error}
                  helperText={telemedicineFormValues.thursday_start.error && telemedicineFormValues.thursday_start.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-thursday-end"
              //label="End"
              name="thursday_end"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.thursday_end.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('thursday_end', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.thursday_end.error}
                  helperText={telemedicineFormValues.thursday_end.error && telemedicineFormValues.thursday_end.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
            Friday
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-friday-start"
              //label="Start"
              name="friday_start"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.friday_start.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('friday_start', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.friday_start.error}
                  helperText={telemedicineFormValues.friday_start.error && telemedicineFormValues.friday_start.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-friday-end"
              //label="End"
              name="friday_end"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.friday_end.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('friday_end', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.friday_end.error}
                  helperText={telemedicineFormValues.friday_end.error && telemedicineFormValues.friday_end.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
            Saturday
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-saturday-start"
              //label="Start"
              name="saturday_start"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.saturday_start.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('saturday_start', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.saturday_start.error}
                  helperText={telemedicineFormValues.saturday_start.error && telemedicineFormValues.saturday_start.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-saturday-end"
              //label="End"
              name="saturday_end"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.saturday_end.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('saturday_end', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.saturday_end.error}
                  helperText={telemedicineFormValues.saturday_end.error && telemedicineFormValues.saturday_end.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <Typography variant='h6' component='h6' className={classes.fontSizeThird}>
            Sunday
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-sunday-start"
              //label="Start"
              name="sunday_start"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.sunday_start.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('sunday_start', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.sunday_start.error}
                  helperText={telemedicineFormValues.sunday_start.error && telemedicineFormValues.sunday_start.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <TimePicker
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-sunday-end"
              //label="End"
              name="sunday_end"
              variant="outlined"
              size="small"
              focused
              required
              value={telemedicineFormValues.sunday_end.value}
              ampm={false}
              onChange={(value) => telemedicineHandleChange('sunday_end', value)}
              renderInput={(params) => (
                <TextField 
                  error={telemedicineFormValues.sunday_end.error}
                  helperText={telemedicineFormValues.sunday_end.error && telemedicineFormValues.sunday_end.errorMessage}
                  {...params}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.customPadding}>
          <Button type="submit" variant="outlined" className={classes.containedSecondary}>Update</Button>
        </Grid>
      </Grid>
      </form>
    </Box>
  </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  
  jss360: {
    margin: "30px auto",
    maxWidth: "960px",
    width: "100%"
  },
  jss366: {
    padding: "24px"
  },
  jss388: {
    margin: "30px auto",
    maxWidth: "720px"
  },
  colorSecondary: {
    color: "#969696"
  },
  fontSizeHead: {
    fontSize: "1.5rem",
    color: "#969696",
  },
  fontSizeSecondary: {
    fontSize: "1rem",
    color: "#E1A87E",
    lineHeight: 1.5,
    marginBottom: "1rem"
  },
  fontSizeThird: {
    fontSize: "1rem",
    color: "#47474A",
    lineHeight: 1.5,
    marginBottom: "1rem"
  },
  marginTop: {
    marginTop: "1rem"
  },
  MuiSvgIconColorPrimary: {
    verticalAlign: "bottom"
  },
  fullwidth: {
    width: "100%"
   },
   customPadding: {
    padding: "8px 4px !important"
   },
   containedSecondary: {
    color: "#fff",
    backgroundColor: "#696969 !important"
  },
  inputstyle: {
    borderWidth: "1px !important",
    borderColor: "rgba(0, 0, 0, 0.23) !important"
  },
  error: {
    display: 'block',
    textAlign: 'center',
    marginTop: 8,
    color: 'red',
    flexBasis: '100%'
  },
  errorBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0'
  },
}));