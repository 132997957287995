import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';


export default function PracticeInformation(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState('');
  const {doctorList, refreshDoctorList, snackCallback} = props;

  const [practiceFormValues, setPracticeFormValues] = useState({
    website:{
      value: ( doctorList[0] !== undefined && doctorList[0].practitionerWebsite !== undefined) ? doctorList[0].practitionerWebsite : '',
      error:false,
      errorMessage:'You must enter a website'
    },
    phone_number:{
      value: ( doctorList[0] !== undefined && doctorList[0].doctor_address_phoneNumber !== undefined) ? doctorList[0].doctor_address_phoneNumber : '',
      error:false,
      errorMessage:'You must enter a phone number'
    },
    address:{
      value: ( doctorList[0] !== undefined && doctorList[0].doctor_address_address1 !== undefined) ? doctorList[0].doctor_address_address1 : '',
      error:false,
      errorMessage:'You must enter an address'
    },
    office_suite_floor:{
      value: ( doctorList[0] !== undefined && doctorList[0].doctor_address_street !== undefined) ? doctorList[0].doctor_address_street : '',
      error:false,
      errorMessage:'You must enter an Office/Suite/Floor'
    },
    city:{
      value: ( doctorList[0] !== undefined && doctorList[0].doctor_address_city !== undefined) ? doctorList[0].doctor_address_city : '',
      error:false,
      errorMessage:'You must enter a city'
    },
    state:{
      value: ( doctorList[0] !== undefined && doctorList[0].doctor_address_state !== undefined) ? doctorList[0].doctor_address_state : '',
      error:false,
      errorMessage:'You must enter a state'
    },
    zipcode:{
      value: ( doctorList[0] !== undefined && doctorList[0].doctor_address_zip !== undefined) ? doctorList[0].doctor_address_zip : '',
      error:false,
      errorMessage:'You must enter a zip'
    },
    
  });

  const practiceHandleChange = (e) => {
    const {name, value} = e.target;
    setPracticeFormValues({
      ...practiceFormValues,
      [name]:{
        ...practiceFormValues[name],
        value
      }
    })
  }

  const practiceHandleSubmit = (e) => {
    e.preventDefault();

    const pformFields = Object.keys(practiceFormValues);
    let newPracticeFormValues = {...practiceFormValues}
    let isError = false;
    for (let index = 0; index < pformFields.length; index++)
    {
      const pcurrentField = pformFields[index];
      const pcurrentValue = practiceFormValues[pcurrentField].value;

      if(pcurrentValue === '' || pcurrentValue === null)
      {
        isError = true;
        newPracticeFormValues = {
          ...newPracticeFormValues,
          [pcurrentField]:{
            ...newPracticeFormValues[pcurrentField],
            error:true
          }
        }
      }
    }
    setPracticeFormValues(newPracticeFormValues);
    if(isError === false)
    {
      updatePracticeInformation();
    }
  }

  const updatePracticeInformation = () => {
    const api = `${process.env.REACT_APP_API_URL}/doctor/update-practice-information`;
    const axiosConfig = {
      method: 'POST',
      url: api,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {
        npi:sessionStorage.getItem('vsndocnpi'),
        website: practiceFormValues.website.value,
        phone_number: practiceFormValues.phone_number.value,
        address: practiceFormValues.address.value,
        office_suite_floor: practiceFormValues.office_suite_floor.value,
        city: practiceFormValues.city.value,
        state: practiceFormValues.state.value,
        zipcode: practiceFormValues.zipcode.value
      }
    };
    axios(axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          snackCallback('Saved successfully.', 'success');
          setErrors('');
          refreshDoctorList();
        } else {
          setErrors('There was a problem. Please try again or contact IT.');
        }
      })
      .catch((error) => {
        setErrors('There was a problem. Please try again or contact IT.');
      });
  };

  return (
  <Paper elevation={4}>
    <Box className={classes.jss366}>
      <Typography variant='h4' component='h4' className={classes.fontSizeSecondary}>
        Practice Information
      </Typography>
      <Box className={classes.errorBox}>
        <small className={classes.error}>{errors}</small>
      </Box>
      <form noValidate onSubmit={practiceHandleSubmit} >
      <Grid container spacing={1} className={classes.marginTop}>
        <Grid item xs={6} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-website"
              name="website"
              label="Website"
              variant="outlined"
              size="small"
              focused
              required
              value={practiceFormValues.website.value}
              onChange={practiceHandleChange}
              error={practiceFormValues.website.error}
              helperText={practiceFormValues.website.error && practiceFormValues.website.errorMessage}
            />
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-phone-number"
              name="phone_number"
              label="Phone number"
              variant="outlined"
              size="small"
              focused
              required
              value={practiceFormValues.phone_number.value}
              onChange={practiceHandleChange}
              error={practiceFormValues.phone_number.error}
              helperText={practiceFormValues.phone_number.error && practiceFormValues.phone_number.errorMessage}
            />
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-address"
              name="address"
              label="Address"
              variant="outlined"
              size="small"
              focused
              required
              value={practiceFormValues.address.value}
              onChange={practiceHandleChange}
              error={practiceFormValues.address.error}
              helperText={practiceFormValues.address.error && practiceFormValues.address.errorMessage}
            />
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-office-suite-floor"
              name="office_suite_floor"
              label="Office/Suite/Floor"
              variant="outlined"
              size="small"
              focused
              required
              value={practiceFormValues.office_suite_floor.value}
              onChange={practiceHandleChange}
              error={practiceFormValues.office_suite_floor.error}
              helperText={practiceFormValues.office_suite_floor.error && practiceFormValues.office_suite_floor.errorMessage}
            
            />
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-city"
              name="city"
              label="City"
              variant="outlined"
              size="small"
              focused
              required
              value={practiceFormValues.city.value}
              onChange={practiceHandleChange}
              error={practiceFormValues.city.error}
              helperText={practiceFormValues.city.error && practiceFormValues.city.errorMessage}
            
            />
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-state"
              name="state"
              label="State"
              variant="outlined"
              size="small"
              focused
              required
              value={practiceFormValues.state.value}
              onChange={practiceHandleChange}
              error={practiceFormValues.state.error}
              helperText={practiceFormValues.state.error && practiceFormValues.state.errorMessage}
            
            />
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-zip"
              name="zipcode"
              label="Zip"
              variant="outlined"
              size="small"
              focused
              required
              value={practiceFormValues.zipcode.value}
              onChange={practiceHandleChange}
              error={practiceFormValues.zipcode.error}
              helperText={practiceFormValues.zipcode.error && practiceFormValues.zipcode.errorMessage}
            
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.customPadding}>
          <Button type="submit" variant="outlined" className={classes.containedSecondary}>Update</Button>
        </Grid>
      </Grid>
      </form>
    </Box>
  </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  
  jss360: {
    margin: "30px auto",
    maxWidth: "960px",
    width: "100%"
  },
  jss366: {
    padding: "24px"
  },
  jss388: {
    margin: "30px auto",
    maxWidth: "720px"
  },
  colorSecondary: {
    color: "#969696"
  },
  fontSizeHead: {
    fontSize: "1.5rem",
    color: "#969696",
  },
  fontSizeSecondary: {
    fontSize: "1rem",
    color: "#E1A87E",
    lineHeight: 1.5,
    marginBottom: "1rem"
  },
  marginTop: {
    marginTop: "1rem"
  },
  MuiSvgIconColorPrimary: {
    verticalAlign: "bottom"
  },
  fullwidth: {
    width: "100%"
   },
   customPadding: {
    padding: "8px 4px !important"
   },
   containedSecondary: {
    color: "#fff",
    backgroundColor: "#696969 !important"
  },
  inputstyle: {
    borderWidth: "1px !important",
    borderColor: "rgba(0, 0, 0, 0.23) !important"
  },
  error: {
    display: 'block',
    textAlign: 'center',
    marginTop: 8,
    color: 'red',
    flexBasis: '100%'
  },
  errorBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0'
  },
}));