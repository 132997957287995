import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const preferenceList = [
  'Upneeq prescriber',
  'SPARCS Diagnostic exam reviews',
  'Dry Eye Disease prescriber',
  'Glaucoma prescriber',
  'Cataracts prescriber'
];

export default function Preferences(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState('');
  const {doctorList, refreshDoctorList, snackCallback} = props;
  
  const [preferencesFormValues, setPreferenceFormValues] = useState({
    preference:{
      value: (doctorList[0] !== undefined && doctorList[0].preference !== undefined) ? JSON.parse(doctorList[0].preference) : [],
      error:false,
      errorMessage:'You must select a preference'
    },
    
  });

  const preferencesHandleChange = (e, values) => {
    const prname = 'preference';
    preferencesFormValues.preference.value = values;
    setPreferenceFormValues({
      ...preferencesFormValues,
      [prname]:{
        ...preferencesFormValues[prname],
        values
      }
    });
  }

  const preferencesHandleSubmit = (e) => {
    e.preventDefault();

    const prformFields = Object.keys(preferencesFormValues);
    let newPreferenceFormValues = {...preferencesFormValues}
    let isError = false;

    for (let index = 0; index < prformFields.length; index++) {
      const prcurrentField = prformFields[index];
      const prcurrentValue = preferencesFormValues[prcurrentField].value;
      
      if(prcurrentValue.length == 0){
        isError = true;
        newPreferenceFormValues = {
          ...newPreferenceFormValues,
          [prcurrentField]:{
            ...newPreferenceFormValues[prcurrentField],
            error:true
          }
        }
      }
    }
    setPreferenceFormValues(newPreferenceFormValues);
    if(isError === false)
    {
      updatePreferences();
    }
  }

  const updatePreferences = () => {
    const api = `${process.env.REACT_APP_API_URL}/doctor/update-preferences`;
    const axiosConfig = {
      method: 'POST',
      url: api,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {
        npi:sessionStorage.getItem('vsndocnpi'),
        preferences: preferencesFormValues.preference.value,
      }
    };
    axios(axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          snackCallback('Saved successfully.', 'success');
          setErrors('');
          refreshDoctorList();
        } else {
          setErrors('There was a problem. Please try again or contact IT.');
        }
      })
      .catch((error) => {
        setErrors('There was a problem. Please try again or contact IT.');
      });
  };

  
  return (
  <Paper elevation={4}>
    <Box className={classes.jss366}>
      <Typography variant='h4' component='h4' className={classes.fontSizeSecondary}>
        Preferences
      </Typography>
      <Box className={classes.errorBox}>
        <small className={classes.error}>{errors}</small>
      </Box>
      <form noValidate onSubmit={preferencesHandleSubmit}>
      <Grid container spacing={1} className={classes.marginTop}>
        <Grid item xs={6} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
          <FormControl className={classes.fullwidth}>
            <Autocomplete
              id="size-small-outlined"
              className={classes.fullwidth}
              size="small"
              multiple
              name="preference"
              required
              value={preferencesFormValues.preference.value}
              onChange={(event, value) => preferencesHandleChange(event, value)}
              options={preferenceList}
              renderOption={(preference) => preference}
              getOptionLabel={(preference) => preference}
              renderInput={(params) => (
                <TextField 
                  error={preferencesFormValues.preference.error}
                  helperText={preferencesFormValues.preference.error && preferencesFormValues.preference.errorMessage}
                  {...params}
                  variant='outlined'
                  label="I'm interested in referrals for:"
                  placeholder="I'm interested in referrals for:"
                />
              )}
            />
          </FormControl>
          </Box>
        </Grid>
        
        <Grid item xs={12} className={classes.customPadding}>
          <Button type="submit" variant="outlined" className={classes.containedSecondary}>Update</Button>
        </Grid>
      </Grid>
      </form>
    </Box>
  </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  
  jss360: {
    margin: "30px auto",
    maxWidth: "960px",
    width: "100%"
  },
  jss366: {
    padding: "24px"
  },
  jss388: {
    margin: "30px auto",
    maxWidth: "720px"
  },
  colorSecondary: {
    color: "#969696"
  },
  fontSizeHead: {
    fontSize: "1.5rem",
    color: "#969696",
  },
  fontSizeSecondary: {
    fontSize: "1rem",
    color: "#E1A87E",
    lineHeight: 1.5,
    marginBottom: "1rem"
  },
  marginTop: {
    marginTop: "1rem"
  },
  MuiSvgIconColorPrimary: {
    verticalAlign: "bottom"
  },
  fullwidth: {
    width: "100%"
   },
   customPadding: {
    padding: "8px 4px !important"
   },
   containedSecondary: {
    color: "#fff",
    backgroundColor: "#696969 !important"
  },
  inputstyle: {
    borderWidth: "1px !important",
    borderColor: "rgba(0, 0, 0, 0.23) !important"
  },
  error: {
    display: 'block',
    textAlign: 'center',
    marginTop: 8,
    color: 'red',
    flexBasis: '100%'
  },
  errorBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0'
  },
}));