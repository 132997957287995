import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
/*
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
*/

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PersonalInformation from './settings/personalInformation';
import LicensingInformation from './settings/licensingInformation';
import PracticeInformation from './settings/practiceInformation';
import Preferences from './settings/preferences';
import TelemedicineSchedule from './settings/telemedicineSchedule';
import BankInformation from './settings/bankInformation';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Setting(props) {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState({
    message: '',
    severity: ''
  });

  const [doctorList, setDoctorList] = useState([]);
  const [noData, setNoData] = useState(false);


  const handleSnackOpen = (message, severity) => {
    setSnack({
      message: message,
      severity: severity
    });
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    setSnackOpen(false);
  };

  const refreshDoctorList = (event, reason) => {
    getDoctor();
  };

  const getDoctor = () => {
    const docApi = `${process.env.REACT_APP_API_URL}/doctor/listvianpi`;
    const docAxiosConfig = {
      method: 'POST',
      url: docApi,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'X-CSRF-TOKEN': sessionStorage.getItem('vsncodeAdm'),
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {npi:sessionStorage.getItem('vsndocnpi')}
    };
    axios(docAxiosConfig)
      .then((response) => {
        if (response.data.code === 200) {
          setDoctorList(response.data.response);
        } else {
          setNoData(true);
        }
      })
      .catch((error) => {
        setNoData(true);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    getDoctor();
  }, []);
  
  return (
    <Box className={classes.body}>
      <Box className={classes.membershipInformation}>
        
        <Grid container spacing={2} className={[classes.jss360]}>
          <Grid xs={12} md={12}>
            <Box>
                <div className={classes.secondaryMenu}>
                  <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label='Personal Info' />
                    <Tab label='Licensing Info' />
                    <Tab label='Practice Info' />
                    <Tab label='Preferences' />
                    <Tab label='Availability' />
                    <Tab label='Bank Info' />
                  </Tabs>
                </div>

                <TabPanel value={tab} index={0}>
                  <Box className={classes.jss388}>
                  {doctorList.length > 0 && <PersonalInformation doctorList={doctorList} refreshDoctorList={refreshDoctorList}  snackCallback={(message, severity) => handleSnackOpen(message, severity)} />}
                  </Box>
                </TabPanel>

                <TabPanel value={tab} index={1}>
                  <Box className={classes.jss388}>
                    <LicensingInformation
                      doctorList={doctorList}
                      refreshDoctorList={refreshDoctorList}
                      snackCallback={(message, severity) => handleSnackOpen(message, severity)} 
                    />
                  </Box>
                </TabPanel>

                <TabPanel value={tab} index={2}>
                  <Box className={classes.jss388}>
                    <PracticeInformation
                      doctorList={doctorList}
                      refreshDoctorList={refreshDoctorList}
                      snackCallback={(message, severity) => handleSnackOpen(message, severity)} 
                    />
                  </Box>
                </TabPanel>

                <TabPanel value={tab} index={3}>
                  <Box className={classes.jss388}>
                    <Preferences
                      doctorList={doctorList}
                      refreshDoctorList={refreshDoctorList}
                      snackCallback={(message, severity) => handleSnackOpen(message, severity)} 
                    />
                  </Box>
                </TabPanel>

                <TabPanel value={tab} index={4}>
                  <Box className={classes.jss388}>
                    <TelemedicineSchedule
                      doctorList={doctorList}
                      refreshDoctorList={refreshDoctorList}
                      snackCallback={(message, severity) => handleSnackOpen(message, severity)} 
                    />
                  </Box>
                </TabPanel>

                <TabPanel value={tab} index={5}>
                  <Box className={classes.jss388}>
                    <BankInformation
                      doctorList={doctorList}
                      refreshDoctorList={refreshDoctorList}
                      snackCallback={(message, severity) => handleSnackOpen(message, severity)} 
                    />
                  </Box>
                </TabPanel>

            </Box>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              open={snackOpen}
              autoHideDuration={6000}
              onClose={handleSnackClose}>
              <Alert onClose={handleSnackClose} severity={snack.severity}>
                {snack.message}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>

      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  body: {
    padding: '0',
    margin: '60px auto 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    background: '#efefef'
  },
  membershipInformation: {
    flex: '0 0 100%',
    '& > .MuiPaper-root': {
      height: '100%'
    }
  },
  jss360: {
    margin: "30px auto",
    maxWidth: "960px",
    width: "100%"
  },
  jss366: {
    padding: "24px"
  },
  jss388: {
    margin: "30px auto",
    maxWidth: "720px"
  },
  colorSecondary: {
    color: "#969696"
  },
  fontSizeHead: {
    fontSize: "1.5rem",
    color: "#969696",
  },
  fontSizeSecondary: {
    fontSize: "1rem",
    color: "#E1A87E",
    lineHeight: 1.5,
    marginBottom: "1rem"
  },
  marginTop: {
    marginTop: "1rem"
  },
  MuiSvgIconColorPrimary: {
    verticalAlign: "bottom"
  },
  fullwidth: {
    width: "100%"
   },
   customPadding: {
    padding: "8px 4px !important"
   },
   containedSecondary: {
    color: "#fff",
    backgroundColor: "#696969 !important"
  },
  inputstyle: {
    borderWidth: "1px !important",
    borderColor: "rgba(0, 0, 0, 0.23) !important"
  }
}));