import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default function BankInformation(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState('');
  const {doctorList, refreshDoctorList, snackCallback} = props;

  const [bankFormValues, setBankFormValues] = useState({
    bank_name:{
      value: (doctorList[0] !== undefined && doctorList[0].bankName !== undefined) ? doctorList[0].bankName : '',
      error:false,
      errorMessage:'You must enter a bank name'
    },
    routing_number:{
      value:(doctorList[0] !== undefined && doctorList[0].routingNumber !== undefined) ? doctorList[0].routingNumber.replace(/\b[\dX][-. \dX]+(\d{4})\b/g, function(match, capture) {
        return Array(match.length-4).join("*") + capture;
    }) : '',
      error:false,
      errorMessage:'You must enter a routing number'
    },
    account_number:{
      value:(doctorList[0] !== undefined && doctorList[0].accountNumber !== undefined) ? doctorList[0].accountNumber.replace(/\b[\dX][-. \dX]+(\d{4})\b/g, function(match, capture) {
        return Array(match.length-4).join("*") + capture;
    }) : '',
      error:false,
      errorMessage:'You must enter an account number'
    },
  });

  const bankHandleChange = (e) => {
    const {name, value} = e.target;
    setBankFormValues({
      ...bankFormValues,
      [name]:{
        ...bankFormValues[name],
        value
      }
    })
  }

  const bankHandleSubmit = (e) => {
    e.preventDefault();

    const bformFields = Object.keys(bankFormValues);
    let newBankFormValues = {...bankFormValues}
    let isError = false;
    for (let index = 0; index < bformFields.length; index++) {
      const bcurrentField = bformFields[index];
      const bcurrentValue = bankFormValues[bcurrentField].value;

      if(bcurrentValue === ''){
        isError = true;
        newBankFormValues = {
          ...newBankFormValues,
          [bcurrentField]:{
            ...newBankFormValues[bcurrentField],
            error:true
          }
        }
      }
    }
    setBankFormValues(newBankFormValues);
    if(isError === false)
    {
      updateBankInformation();
    }
  }

  const updateBankInformation = () => {
    const api = `${process.env.REACT_APP_API_URL}/doctor/update-bank-information`;
    const axiosConfig = {
      method: 'POST',
      url: api,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {
        npi:sessionStorage.getItem('vsndocnpi'),
        bank_name: bankFormValues.bank_name.value,
        routing_number: bankFormValues.routing_number.value,
        account_number: bankFormValues.account_number.value,
      }
    };
    axios(axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          snackCallback('Saved successfully.', 'success');
          setErrors('');
          refreshDoctorList();
        } else {
          setErrors('There was a problem. Please try again or contact IT.');
        }
      })
      .catch((error) => {
        setErrors('There was a problem. Please try again or contact IT.');
      });
  };

  return (
  <Paper elevation={4}>
    <Box className={classes.jss366}>
      <Typography variant='h4' component='h4' className={classes.fontSizeSecondary}>
        Bank Information
      </Typography>
      <Box className={classes.errorBox}>
        <small className={classes.error}>{errors}</small>
      </Box>
      <form noValidate onSubmit={bankHandleSubmit}>
      <Grid container spacing={1} className={classes.marginTop}>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-bank-name"
              name="bank_name"
              label="Bank name"
              variant="outlined"
              size="small"
              focused
              required
              value={bankFormValues.bank_name.value}
              onChange={bankHandleChange}
              error={bankFormValues.bank_name.error}
              helperText={bankFormValues.bank_name.error && bankFormValues.bank_name.errorMessage}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-routing-number"
              name="routing_number"
              label="Routing Number"
              variant="outlined"
              size="small"
              focused
              required
              value={bankFormValues.routing_number.value}
              onChange={bankHandleChange}
              error={bankFormValues.routing_number.error}
              helperText={bankFormValues.routing_number.error && bankFormValues.routing_number.errorMessage}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-account-number"
              name="account_number"
              label="Account Number"
              variant="outlined"
              size="small"
              focused
              required
              value={bankFormValues.account_number.value}
              onChange={bankHandleChange}
              error={bankFormValues.account_number.error}
              helperText={bankFormValues.account_number.error && bankFormValues.account_number.errorMessage}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.customPadding}>
          <Typography variant='p' component='p' className={classes.fontSizeP}>
            Please contact us to update your license information:
          </Typography>
          <List>
            <ListItem component="li">
              <ListItemText
                primary = "Call"
                secondary = {
                  <Link href="tel:+18446372020" color="inherit" underline="always">
                    {'(844) 637 2020'}
                  </Link>
                }
              />
            </ListItem>
            <ListItem component="li">
              <ListItemText 
                primary = "Email"
                secondary = {
                  <Link href="mailto:info@visionology.com" color="inherit" underline="always">
                    {'info@visionology.com.'}
                  </Link>
                }
              />
            </ListItem>
        </List>
          
        </Grid>

      </Grid>
      </form>
    </Box>
  </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  
  jss360: {
    margin: "30px auto",
    maxWidth: "960px",
    width: "100%"
  },
  jss366: {
    padding: "24px"
  },
  jss388: {
    margin: "30px auto",
    maxWidth: "720px"
  },
  colorSecondary: {
    color: "#969696"
  },
  fontSizeHead: {
    fontSize: "1.5rem",
    color: "#969696",
  },
  fontSizeSecondary: {
    fontSize: "1rem",
    color: "#E1A87E",
    lineHeight: 1.5,
    marginBottom: "1rem"
  },
  marginTop: {
    marginTop: "1rem"
  },
  MuiSvgIconColorPrimary: {
    verticalAlign: "bottom"
  },
  fullwidth: {
    width: "100%"
   },
   customPadding: {
    padding: "8px 4px !important"
   },
   containedSecondary: {
    color: "#fff",
    backgroundColor: "#696969 !important"
  },
  inputstyle: {
    borderWidth: "1px !important",
    borderColor: "rgba(0, 0, 0, 0.23) !important"
  },
  error: {
    display: 'block',
    textAlign: 'center',
    marginTop: 8,
    color: 'red',
    flexBasis: '100%'
  },
  errorBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0'
  },
}));