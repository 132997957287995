import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import {DatePicker} from '@material-ui/pickers';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassFull';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Balance(props) {
  const classes = useStyles();

  const [transactionsData, setTransactionsData] = useState([]);
  const [doctorPaymentStatistics, setDoctorPaymentStatistics] = useState([]);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [maxItems, setMaxItems] = useState(1);

  const [noData, setNoData] = useState(false);
  const [tab, setTab] = useState(0);

  const addLeadingZeros = (num, totalLength) => {
    return String(num).padStart(totalLength, '0');
  }

  const getTransactions = (page) => {

    const presApi = `${process.env.REACT_APP_API_URL}/order/listvianpi`;
    const presAxiosConfig = {
      method: 'POST',
      url: presApi,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'X-CSRF-TOKEN': sessionStorage.getItem('vsncodeAdm'),
        'Pagination-Limit': 100,
        'Pagination-Page': page,
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {npi:sessionStorage.getItem('vsndocnpi')}
    };
    axios(presAxiosConfig)
      .then((response) => {
        if (response.data.code === 200) {
          //console.log(response);
          setTransactionsData(response.data.response.filter((order) => (order.status === 'paid' || order.status === 'cancelled')));
        } else {
          setNoData(true);
        }
      })
      .catch((error) => {
        setNoData(true);
      });
  };

  const getDoctorPaymentStatistics = () => {

    const presApi = `${process.env.REACT_APP_API_URL}/order/doctor-payment-statistics`;
    const presAxiosConfig = {
      method: 'POST',
      url: presApi,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'X-CSRF-TOKEN': sessionStorage.getItem('vsncodeAdm'),
        'Pagination-Limit': 100,
        'Pagination-Page': page,
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {npi:sessionStorage.getItem('vsndocnpi')}
    };
    axios(presAxiosConfig)
      .then((response) => {
        if (response.data.code === 200) {
          //console.log(response);
          setDoctorPaymentStatistics(response.data.response);
        } else {
          setNoData(true);
        }
      })
      .catch((error) => {
        setNoData(true);
      });
  };

  useEffect(() => {
    getTransactions(page);
    getDoctorPaymentStatistics();
  }, []);

  return (
    <Box className={classes.body}>
      <Box className={classes.membershipInformation}>
          <Paper elevation={4}>
            <TableContainer component={Box} className={classes.tableContainer}>
              <Table size='small' className={classes.table} aria-label='orders table'>
                <TableHead>
                  <TableRow>
                    <TableCell>This Week</TableCell>
                    <TableCell>This Month</TableCell>
                    <TableCell>Year-to-Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {doctorPaymentStatistics &&
                      <TableRow>
                        <TableCell>${doctorPaymentStatistics.WEEK}</TableCell>
                        <TableCell>${doctorPaymentStatistics.MONTH}</TableCell>
                        <TableCell>${doctorPaymentStatistics.YEAR}</TableCell>
                      </TableRow>
                    }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
      </Box>
      <Box className={classes.membershipInformation2}>
          <Paper elevation={4}>
            <TableContainer component={Box} className={classes.tableContainer}>
              <Table size='small' className={classes.table} aria-label='orders table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Patient Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Transaction Date</TableCell>
                    <TableCell>Prescription Id</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionsData &&
                    transactionsData.map((order, index) => {
                      return (
                        <TableRow key={order.id}>
                          <TableCell>
                          {order.first_name.charAt(0).toUpperCase() + order.first_name.slice(1)}{' '}
                          {order.last_name.charAt(0).toUpperCase() + order.last_name.slice(1)}
                          </TableCell>
                          <TableCell>{order.prescription_request_status.toUpperCase()}</TableCell>
                          <TableCell>${order.doctor_amount}</TableCell>
                          <TableCell>
                            {new Date(order.createdAt).toLocaleDateString([], {
                              month: '2-digit',
                              day: '2-digit',
                              year: 'numeric'
                            })}{' '}
                            {new Date(order.createdAt).toLocaleTimeString([], {
                              minute: '2-digit',
                              hour: 'numeric'
                            })}
                          </TableCell>
                          <TableCell>OP{order.id}-{addLeadingZeros(order.prescription_id,5)}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
      </Box>
      
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  body: {
    padding: '0',
    margin: '60px auto 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    background: '#efefef',
    maxWidth: '780px'
  },
  membershipInformation: {
    paddingTop: 60,
    '& > .MuiPaper-root': {
      height: '100%'
    }
  },
  membershipInformation2: {
    paddingTop: 60,
    '& > .MuiPaper-root': {
      height: '100%'
    }
  },
  secondaryMenu: {
    padding: '8px 16px',
    background: `linear-gradient(30deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
    fontSize: '1.3em',
    fontWeight: 700,
    lineHeight: 1,
    color: '#fff',
    width: '100%',
    boxSizing: 'border-box',
    '& .Mui-selected': {
      color: '#ffbf6d'
    }
  },
  createButton: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 10,
    right: 10,
    color: '#fff'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  flex: {
    display: 'flex'
  },
  actions: {
    display: 'inline-block'
  },
  searchBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: '1 1 100%'
  }
  /*tableContainer: {
    maxHeight: 'calc(100vh - 60px - 64px - 48px)'
  }*/
}));
