import axios from 'axios';
import { Avatar, Button as MuiButton, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {
  CloudUpload as MuiCloudUpload,
  Delete as MuiDelete,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React, { createRef, useState, useEffect } from "react";
import styled from "styled-components";

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);
const DeleteIcon = styled(MuiDelete)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
  margin: auto;
  ${({ $withBorder }) =>
    $withBorder &&
    `border: 1px solid ${grey[500]};
     box-shadow: 0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]};`}
`;

export default function AvatarUpload(props) {
  const classes = useStyles();
  const [image, _setImage] = useState(null);
  const [tempFile, setTempFile] = useState(null);
  const inputFileRef = createRef(null);
  const [errors, setErrors] = useState('');
  const {doctorList, refreshDoctorList, snackCallback} = props;

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };

  const handleOnChange = (event) => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      setImage(URL.createObjectURL(newImage));
      setTempFile(newImage);
      submit(newImage);
    }
  };

  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
  const handleClick = (event) => {
    if (image) {
      event.preventDefault();
      setImage(null);
      remove();
    }
  };

  useEffect(() => {
    if(doctorList.length > 0 && doctorList[0] !== undefined && doctorList[0].profileImage !== undefined && doctorList[0].profileImage !== '' && doctorList[0].profileImage !== null)
    {
      const dimage = `${process.env.REACT_APP_PROFILE_IMAGE_URL}` + doctorList[0].profileImage;
      setImage(dimage);
    }
  }, []);

  const submit = (tempFilePath) => {

      const formData = new FormData() 
      formData.append('profileImage', tempFilePath);
      formData.append('npi', sessionStorage.getItem('vsndocnpi'));
      //console.warn(tempFilePath);

      const api = `${process.env.REACT_APP_API_URL}/doctor/update-profile-image`;
      const axiosConfig = {
        method: 'POST',
        url: api,
        //withCredentials: 'true',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Cache-Control': 'no-cache',
          Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
        },
        data: formData,
      };
      axios(axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            snackCallback('Saved successfully.', 'success');
            setErrors('');
          } else {
            setErrors('There was a problem. Please try again or contact IT.');
          }
        })
        .catch((error) => {
          setErrors('There was a problem. Please try again or contact IT.');
        });
  };

  const remove = () => {

    const formData = new FormData() 
    formData.append('npi', sessionStorage.getItem('vsndocnpi'));

    const api = `${process.env.REACT_APP_API_URL}/doctor/remove-profile-image`;
    const axiosConfig = {
      method: 'POST',
      url: api,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: formData,
    };
    axios(axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          snackCallback('Removed successfully.', 'success');
          setErrors('');
        } else {
          setErrors('There was a problem. Please try again or contact IT.');
        }
      })
      .catch((error) => {
        setErrors('There was a problem. Please try again or contact IT.');
      });
};

  return (
    <CenteredContent>
      <Box className={classes.errorBox}>
        <small className={classes.error}>{errors}</small>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <BigAvatar
            $withBorder
            alt="Avatar"
            src={image || "/static/img/avatars/default-profile.svg"}
            imgProps={{
              style: {
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "cover",
              },
            }}
          />  
        </Grid>
        <Grid item xs={8}>
            <Box>
              <input
                ref={inputFileRef}
                accept="image/*"
                hidden
                id="avatar-image-upload"
                type="file"
                onChange={handleOnChange}
              />
              <label htmlFor="avatar-image-upload" className="uploadbutton">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  mb={2}
                  onClick={handleClick}
                >
                  {image ? <DeleteIcon mr={2} /> : <UploadIcon mr={2} />}
                  {image ? "Remove" : "Upload"}
                </Button>
              </label>
              <Typography variant="caption" display="block" gutterBottom className="themeColor">
                For best results, use an image of at least 128x128 pixels in .jpg format
              </Typography>
            </Box>
          </Grid>
      </Grid>
    </CenteredContent>
  );
}

const useStyles = makeStyles((theme) => ({
  error: {
    display: 'block',
    textAlign: 'center',
    marginTop: 8,
    color: 'red',
    flexBasis: '100%'
  },
  errorBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0'
  },
}));