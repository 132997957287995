import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

export default function Dashboard(props) {
  const classes = useStyles();
  const [doctorList, setDoctorList] = useState([]);

  const getDoctor = () => {
    const docApi = `${process.env.REACT_APP_API_URL}/doctor/dashboard`;
    const docAxiosConfig = {
      method: 'POST',
      url: docApi,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'X-CSRF-TOKEN': sessionStorage.getItem('vsncodeAdm'),
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {npi:sessionStorage.getItem('vsndocnpi')}
    };
    axios(docAxiosConfig)
      .then((response) => {
        if (response.data.code === 200) {
          setDoctorList(response.data.response);
        }
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    getDoctor();
  }, []);

  return (
    <Box className={classes.body}>
      <Box className={classes.membershipInformation}>
        
        <Grid container spacing={2} className={classes.jss360}>
          <Grid xs={12} md={12}>
            <Box>
              <Paper elevation={4}>
                <Box className={classes.jss366}>
                  <Typography variant='h4' component='h4' className={classes.colorSecondary}>
                    Welcome back, Dr. {( doctorList.firstName !== undefined) ? doctorList.firstName : '-'}
                  </Typography>
                  <Box className={classes.marginTop}>
                    <svg className="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIconColorPrimary verticalAlignBottom" focusable="false" viewBox="0 0 24 24" aria-hidden="true" size="20"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg>
                    <Typography variant='h3' component='h3' className={classes.fontSizeSecondary}>
                      You have <strong>{( doctorList.pending_prescriptions !== undefined) ? doctorList.pending_prescriptions : '0'}</strong> prescriptions waiting for you.
                    </Typography>
                  </Box>
                  <Box className={classes.marginTop}>
                    <Link href="/orders" underline="none">
                      <Button variant="outlined" className={classes.colorSecondary}>Review Prescriptions</Button>
                    </Link>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.jss360}>
          <Grid xs={6} md={6}>
            <Box>
              <Paper elevation={4} className={classes.marginRight}>
                <Box className={classes.jss366}>
                  <Typography variant='h4' component='h4' className={classes.fontSizeHead}>
                    Account balance
                  </Typography>
                  <Box className={classes.marginTop}>
                    <Typography variant='h3' component='h3' className={classes.fontSizeSecondary}>
                      You currently have <strong>${( doctorList.balance !== undefined) ? doctorList.balance : '0'}</strong> in your Visionology account.
                    </Typography>
                  </Box>
                  <Box className={classes.marginTop}>
                    <Link href="javascript:void(0);" underline="none">
                      <Button variant="outlined" className={classes.colorSecondary}>Withdrawal Request</Button>
                    </Link>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Grid>

          <Grid xs={6} md={6}>
            <Box>
              <Paper elevation={4} className={classes.marginLeft}>
                <Box className={classes.jss366}>
                  <Typography variant='h4' component='h4' className={classes.fontSizeHead}>
                    Account settings
                  </Typography>
                  <Box className={classes.marginTop}>
                    <Typography variant='h3' component='h3' className={classes.fontSizeSecondary}>
                      Update your information, view preferences and adjust your schedule.
                    </Typography>
                  </Box>
                  <Box className={classes.marginTop}>
                    <Link href="/setting" underline="none">
                      <Button variant="outlined" className={classes.colorSecondary}>Update Settings</Button>
                    </Link>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Grid>

        </Grid>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  body: {
    padding: '0',
    margin: '60px auto 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    background: '#efefef'
  },
  membershipInformation: {
    flex: '0 0 100%',
    '& > .MuiPaper-root': {
      height: '100%'
    }
  },
  jss360: {
    margin: "30px auto",
    maxWidth: "960px"
  },
  jss366: {
    padding: "24px"
  },
  colorSecondary: {
    color: "#969696"
  },
  fontSizeHead: {
    fontSize: "1.5rem",
    color: "#969696",
  },
  fontSizeSecondary: {
    fontSize: "1rem",
    color: "#969696",
    lineHeight: 1.5,
    display: "inline-block"
  },
  marginTop: {
    marginTop: "1rem"
  },
  MuiSvgIconColorPrimary: {
    verticalAlign: "bottom"
  },
  marginRight: {
    marginRight: "0.5rem"
  },
  marginLeft: {
    marginRight: "0.5rem"
  }
}));