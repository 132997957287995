import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default function LicensingInformation(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState('');
  const {doctorList, refreshDoctorList, snackCallback} = props;

  const [licensingFormValues, setLicensingFormValues] = useState({
    npi:{
      value: ( doctorList[0] !== undefined && doctorList[0].npi !== undefined) ? doctorList[0].npi : '',
      error:false,
      errorMessage:'You must enter a NPI'
    },
    specialty:{
      value: ( doctorList[0] !== undefined && doctorList[0].specialty !== undefined) ? doctorList[0].specialty : '',
      error:false,
      errorMessage:'You must enter a specialty'
    },
    practice_year:{
      value: ( doctorList[0] !== undefined && doctorList[0].yearsInPractice !== undefined) ? doctorList[0].yearsInPractice : '',
      error:false,
      errorMessage:'You must enter a years in practice'
    },
  });

  const licensingHandleChange = (e) => {
    const {name, value} = e.target;
    setLicensingFormValues({
      ...licensingFormValues,
      [name]:{
        ...licensingFormValues[name],
        value
      }
    })
  }

  const licensingHandleSubmit = (e) => {
    e.preventDefault();

    const lformFields = Object.keys(licensingFormValues);
    let newLicensingFormValues = {...licensingFormValues}
    let isError = false;
    for (let index = 0; index < lformFields.length; index++) {
      const lcurrentField = lformFields[index];
      const lcurrentValue = licensingFormValues[lcurrentField].value;

      if(lcurrentValue === '' || lcurrentValue === null){
        isError = true;
        newLicensingFormValues = {
          ...newLicensingFormValues,
          [lcurrentField]:{
            ...newLicensingFormValues[lcurrentField],
            error:true
          }
        }
      }

    }
    setLicensingFormValues(newLicensingFormValues);
    if(isError === false)
    {
      updateLicensingInformation();
    }
  }

  const updateLicensingInformation = () => {
    const api = `${process.env.REACT_APP_API_URL}/doctor/update-licensing-information`;
    const axiosConfig = {
      method: 'POST',
      url: api,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {
        npi:sessionStorage.getItem('vsndocnpi'),
        specialty: licensingFormValues.specialty.value,
        practice_year: licensingFormValues.practice_year.value,
      }
    };
    axios(axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          snackCallback('Saved successfully.', 'success');
          setErrors('');
          refreshDoctorList();
        } else {
          setErrors('There was a problem. Please try again or contact IT.');
        }
      })
      .catch((error) => {
        setErrors('There was a problem. Please try again or contact IT.');
      });
  };

  return (
  <Paper elevation={4}>
    <Box className={classes.jss366}>
      <Typography variant='h4' component='h4' className={classes.fontSizeSecondary}>
        Licensing Information
      </Typography>
      <Box className={classes.errorBox}>
        <small className={classes.error}>{errors}</small>
      </Box>
      <form noValidate onSubmit={licensingHandleSubmit} >
      <Grid container spacing={1} className={classes.marginTop}>
        <Grid item xs={5} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={[classes.fullwidth, classes.inputstyle]}
              id="outlined-npi-number"
              name="npi"
              label="NPI number"
              defaultValue=""
              variant="outlined"
              size="small"
              focused
              required
              value={licensingFormValues.npi.value}
              onChange={licensingHandleChange}
              error={licensingFormValues.npi.error}
              helperText={licensingFormValues.npi.error && licensingFormValues.npi.errorMessage}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-specialty"
              name="specialty"
              label="Specialty"
              defaultValue="Cataracts surgeon"
              variant="outlined"
              size="small"
              focused
              required
              value={licensingFormValues.specialty.value}
              onChange={licensingHandleChange}
              error={licensingFormValues.specialty.error}
              helperText={licensingFormValues.specialty.error && licensingFormValues.specialty.errorMessage}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <TextField
              className={classes.fullwidth}
              id="outlined-years-in-practice"
              name="practice_year"
              label="Years in Practice"
              variant="outlined"
              size="small"
              focused
              required
              value={licensingFormValues.practice_year.value}
              onChange={licensingHandleChange}
              error={licensingFormValues.practice_year.error}
              helperText={licensingFormValues.practice_year.error && licensingFormValues.practice_year.errorMessage}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5} className={classes.customPadding}>
          <Box className={classes.fullwidth}>
            <FormControl size="small" className={classes.fullwidth}>
              <InputLabel id="licensed-in">Licensed in:</InputLabel>
              <Select
                className={classes.fullwidth}
                labelId="licensed-in"
                name="licensed_in"
                id="licensed-in"
                label="Licensed in:"
                value=" "
                variant="outlined"
                focused
              >
                <MenuItem value=" ">
                  <em>None</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.customPadding}>
          <Typography variant='p' component='p' className={classes.fontSizeP}>
            Please contact us to update your license information:
          </Typography>
          <List>
            <ListItem component="li">
              <ListItemText
                primary = "Call"
                secondary = {
                  <Link href="tel:+18446372020" color="inherit" underline="always">
                    {'(844) 637 2020'}
                  </Link>
                }
              />
            </ListItem>
            <ListItem component="li">
              <ListItemText 
                primary = "Email"
                secondary = {
                  <Link href="mailto:info@visionology.com" color="inherit" underline="always">
                    {'info@visionology.com.'}
                  </Link>
                }
              />
            </ListItem>
        </List>
          
        </Grid>

      </Grid>
      </form>
    </Box>
  </Paper>
  );
}

const useStyles = makeStyles((theme) => ({

  jss360: {
    margin: "30px auto",
    maxWidth: "960px",
    width: "100%"
  },
  jss366: {
    padding: "24px"
  },
  jss388: {
    margin: "30px auto",
    maxWidth: "720px"
  },
  colorSecondary: {
    color: "#969696"
  },
  fontSizeHead: {
    fontSize: "1.5rem",
    color: "#969696",
  },
  fontSizeP: {
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: 1.5,
    marginBottom: "4px"
  },
  fontSizeSecondary: {
    fontSize: "1rem",
    color: "#E1A87E",
    lineHeight: 1.5,
    marginBottom: "1rem"
  },
  marginTop: {
    marginTop: "1rem"
  },
  MuiSvgIconColorPrimary: {
    verticalAlign: "bottom"
  },
  fullwidth: {
    width: "100%"
   },
   customPadding: {
    padding: "8px 4px !important"
   },
   containedSecondary: {
    color: "#fff",
    backgroundColor: "#696969 !important"
  },
  inputstyle: {
    borderWidth: "1px !important",
    borderColor: "rgba(0, 0, 0, 0.23) !important"
  },
  error: {
    display: 'block',
    textAlign: 'center',
    marginTop: 8,
    color: 'red',
    flexBasis: '100%'
  },
  errorBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0'
  },
}));
