import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Header from "./components/header";
import Orders from "./components/orders";
import Dashboard from "./components/dashboard";
import Setting from "./components/setting";
import Balance from "./components/balance";
import SecuredRoute from "./components/securedRoute";
//import Login from "./components/login";
import { Auth0Provider } from "@auth0/auth0-react";

export default function App() {
  return (
    <Router>
      
        <Switch>
          <SecuredRoute exact path="/dashboard">
            <Header height="60" />
            <Dashboard />
          </SecuredRoute>
          <SecuredRoute exact path="/orders">
            <Header height="60" />
            <Orders />
          </SecuredRoute>
          <SecuredRoute exact path="/balance">
            <Header height="60" />
            <Balance />
          </SecuredRoute>
          <SecuredRoute exact path="/setting">
            <Header height="60" />
            <Setting />
          </SecuredRoute>
          <Route exact path="/">
            <Header height="60" />
            { /* <Login /> */}
            <Dashboard /> 
          </Route>
          <Redirect to="/" />
        </Switch>
    </Router>
  );
}
