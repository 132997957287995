import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function OrderReject(props) {
  const classes = useStyles();
  const {doctorId, prescriptionRequestId, patientId, orderId, noteModalOpen, handleNoteModalClose, callback, snackCallback} = props;
  const [rejectionReason, setRejectionReason] = useState('');
  const [errors, setErrors] = useState('');

  const handleRejectionReasonChange = (event) => {
    setRejectionReason(event.target.value);
  };

  const updateOrder = () => {
    const api = `${process.env.REACT_APP_API_URL}/prescription-request/update-status`;
    const axiosConfig = {
      method: 'POST',
      url: api,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {id:prescriptionRequestId, doctor_id: doctorId, status: 'rejected', rejection_reason: rejectionReason }
    };
    axios(axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          snackCallback('Successfully rejected order.', 'success');
          setErrors('');
          handleNoteModalClose();
          callback();
        } else {
          setErrors('There was a problem rejecting this order. Please try again or contact IT.');
        }
      })
      .catch((error) => {
        setErrors('There was a problem rejecting this order. Please try again or contact IT.');
      });
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={noteModalOpen} onClose={handleNoteModalClose} aria-labelledby='add-patient-note'>
      <DialogTitle id='add-patient-note'>Reject Order</DialogTitle>
      <DialogContent>
        <TextField
          value={rejectionReason}
          onChange={handleRejectionReasonChange}
          multiline
          rows={4}
          autoFocus
          margin='dense'
          id='name'
          label='Please explain the rejection reason'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Box p={2}>
          <Button onClick={() => updateOrder()} color='secondary' variant='contained'>
            Reject application
          </Button>
        </Box>
      </DialogActions>
      <Box className={classes.errorBox}>
        <small className={classes.error}>{errors}</small>
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  success: {
    display: 'block',
    textAlign: 'right',
    marginTop: 8,
    color: 'green'
  },
  error: {
    display: 'block',
    textAlign: 'right',
    marginTop: 8,
    color: 'red',
    flexBasis: '70%'
  },
  errorBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 24px 24px'
  }
}));
