import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

export default function Questionary(props) {
  const classes = useStyles();
  const {doctorId, patientId, orderId, orderData, quesModalOpen, handleQuesModalClose} = props;
  const [errors, setErrors] = useState('');

  const close = () => {
    handleQuesModalClose();
  };

  const formatDob = (dob) => {
    return new Date(dob).toLocaleDateString([], {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  };

  const formatPhone2 = (phoneNumberString1) => {
    if (phoneNumberString1 !== undefined && phoneNumberString1.length === 10) {
      const cleaned = ('' + phoneNumberString1).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      return phoneNumberString1;
    }
  };

  let fields = {
    firstName: orderData.first_name,
    lastName: orderData.last_name,
    phoneNumber: formatPhone2(orderData.phone),
    emailAddress: orderData.email,
    communicationMethod: 'both',
    consent: orderData.consent,
    dob: formatDob(orderData.dob),
  };

  let patientHistory = {
    glaucoma: (orderData.glaucoma == '1') ? true : false,
    cataracts: (orderData.cataracts == '1') ? true : false,
    dryeye: (orderData.dryeye == '1') ? true : false,
    retinal: (orderData.retinal == '1') ? true : false,
    other: orderData.other,
    explanation: orderData.other_diagnosis,
  };

  let gender = {
    female: (orderData.gender == 'F') ? 'contained':'outlined',
    male: (orderData.gender == 'M') ? 'contained':'outlined',
  };

  let contactLens = (orderData.contacts == '1') ? true : false;
  let smoker = (orderData.smoker == '1') ? true : false;
  let pregnant = (orderData.pregnant == '1') ? true : false;
  
  let medications = (orderData.medications !== undefined) ? orderData.medications : [];
  let allergies = (orderData.allergies !== undefined) ? orderData.allergies : [];


  let uncDemsScore = orderData.uncDemsScore;
  let uncDemsScore2 = orderData.uncDemsScore2;

  

  return (
    <Dialog fullWidth maxWidth='sm' open={quesModalOpen} onClose={handleQuesModalClose} aria-labelledby='add-patient-note'>
      <DialogTitle id='add-patient-note'>Questionary</DialogTitle>
      <DialogContent>
        <div className={classes.root} noValidate autoComplete='off'>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box className={classes.questionBox}>
              <Typography variant='h5' component='p'>
                Please review the information.
              </Typography>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <Box className={classes.section}>
              <Typography variant='body2' component='p' className={classes.sectionTitle}>
                <strong>Personal Information</strong>
              </Typography>
              {(
                <>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Name:</span> {fields.firstName} {fields.lastName}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Phone:</span> {fields.phoneNumber}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Email:</span> {fields.emailAddress}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Date of Birth:</span> {fields.dob}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.section}>
              <Typography variant='body2' component='p' className={classes.sectionTitle}>
                <strong>Medical History</strong>
              </Typography>
              {(
                <>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Dry Eye Disease:</span> {patientHistory.dryeye ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Glaucoma:</span> {patientHistory.glaucoma ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Cataracts:</span> {patientHistory.cataracts ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Retinal Disease:</span> {patientHistory.retinal ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Other:</span> {patientHistory.other ? patientHistory.explanation : 'No'}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.section}>
              <Typography variant='body2' component='p' className={classes.sectionTitle}>
                <strong>Medications & Allergies</strong>
              </Typography>
              {(
                <>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Medications:</span> {medications.join(", ")}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Allergies:</span> {allergies.join(", ")}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.section}>
              <Typography variant='body2' component='p' className={classes.sectionTitle}>
                <strong>Dry Eye Scores</strong>
              </Typography>
              <Typography variant='body1' component='p'>
                <span className={classes.fontWeight500}>Symptoms:</span> {uncDemsScore}
              </Typography>
              <Typography variant='body1' component='p'>
                <span className={classes.fontWeight500}>Affect on daily life:</span> {uncDemsScore2}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.section}>
              <Typography variant='body2' component='p' className={classes.sectionTitle}>
                <strong>Other Info</strong>
              </Typography>
              {(
                <>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Gender:</span> {gender.female === 'contained' ? 'Female' : 'Male'}
                  </Typography>
                  {gender.female === 'contained' && (
                    <Typography variant='body1' component='p'>
                      <span className={classes.fontWeight500}>Pregnant:</span> {pregnant ? 'Yes' : 'No'}
                    </Typography>
                  )}
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Smoker:</span> {smoker ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Contact Lenses:</span> {contactLens ? 'Yes' : 'No'}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        </div>  
      </DialogContent>
      <DialogActions>
        <Box p={2}>
          <Button onClick={() => close()} color='secondary' variant='contained'>
            Close
          </Button>
        </Box>
      </DialogActions>
      <Box className={classes.errorBox}>
        <small className={classes.error}>{errors}</small>
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    maxWidth: '650px !important',
  },
  success: {
    display: 'block',
    textAlign: 'right',
    marginTop: 8,
    color: 'green'
  },
  error: {
    display: 'block',
    textAlign: 'right',
    marginTop: 8,
    color: 'red',
    flexBasis: '70%'
  },
  errorBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 24px 24px'
  },
  root: {
    flexGrow: 1,
  },
  questionBox: {
    width: '100%',
    padding: '16px 16px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    boxSizing: 'border-box',
  },
  section: {
    padding: '16px',
  },
  sectionTitle: {
    borderBottom: '1px solid #666',
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  squished: {
    padding: '9px 4px 9px 9px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    color: 'red',
  },
  editSubmit: {
    border: "2px solid #001141",
    color: "#001141",
    padding: "4px 18px !important",
    marginBottom: "8px",
    paddingTop: "8px !important",
  },
  fontWeight500: {
    fontWeight: 500,
  },
  visibilityHidden: {
    display: "none"
  },
  fullWidth: {
    width: '100%'
  }
}));
