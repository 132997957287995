import React, {useEffect, useState} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';

export default function SecuredRoute(props) {
  const {component: Component, ...rest} = props;
  //const {isAuthenticated, isLoading, logout, getAccessTokenSilently} = useAuth0();
  const isAuthenticated = true;
  const isLoading = false;
  const logout = null;
  /*
  useEffect(() => {
    if (false && isAuthenticated) {
      const getToken = async () => {
        const domain = 'dev-40dc7p3i.us.auth0.com';

        try {
          const accessToken = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: 'read:current_user'
          });
          sessionStorage.setItem('vsncodeAdm', accessToken);
          //setToken(accessToken);
        } catch (e) {
          console.log(e.message);
        }
      };

      getToken();
    }
  }, [getAccessTokenSilently, isAuthenticated]);
  */

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return isAuthenticated ? <Route {...rest} render={(props) => <Component {...props} />} /> : logout({returnTo: window.location.origin});
}
