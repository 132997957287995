import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import {DatePicker} from '@material-ui/pickers';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassFull';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import OrderReject from './orderReject';
import OrderApprove from './orderApprove';
import Questionary from './questionary';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Orders(props) {
  const classes = useStyles();
  const [approvedOrdersData, setApprovedOrdersData] = useState([]);
  const [pendingOrdersData, setPendingOrdersData] = useState([]);
  const [rejectedOrdersData, setRejectedOrdersData] = useState([]);
  const [interventionOrdersData, setInterventionOrdersData] = useState([]);
  const [shippedOrdersData, setShippedOrdersData] = useState([]);
  
  const [noData, setNoData] = useState(false);
  const [tab, setTab] = useState(0);
  
  const [orderData, setOrderData] = useState({});
  const [patientId, setPatientId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [prescriptionRequestId, setPrescriptionRequestId] = useState('');

  const [orderListView, setOrderListView] = useState(true);
  
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [maxItems, setMaxItems] = useState(1);

  const [searchFields, setSearchFields] = useState({
    firstName: '',
    lastName: '',
    dob: new Date('1970-01-01')
  });

  const [quesModalOpen, setQuesModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState({
    message: '',
    severity: ''
  });

  

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    getOrders(page);
  };

  const handleQuesModalClose = () => {
    setQuesModalOpen(false);
  };

  const handleApproveModalClose = () => {
    setApproveModalOpen(false);
  };

  const handleQuesModalOpen = (doctorId, orderId, patientId, prescriptionRequestId, orderData) => {
    setDoctorId(doctorId);
    setOrderId(orderId);
    setPatientId(patientId);
    setPrescriptionRequestId(prescriptionRequestId);
    setOrderData(orderData);
    setQuesModalOpen(true);
  };

  const handleApproveModalOpen = (doctorId, orderId, patientId, prescriptionRequestId, orderData) => {
    setDoctorId(doctorId);
    setOrderId(orderId);
    setPatientId(patientId);
    setPrescriptionRequestId(prescriptionRequestId);
    setOrderData(orderData);
    setApproveModalOpen(true);
  };

  const handleRejectModalClose = () => {
    setRejectModalOpen(false);
  };

  const handleRejectModalOpen = (doctorId, orderId, patientId, prescriptionRequestId) => {
    setDoctorId(doctorId);
    setOrderId(orderId);
    setPatientId(patientId);
    setPrescriptionRequestId(prescriptionRequestId);
    setRejectModalOpen(true);
  };

  const handleSnackOpen = (message, severity) => {
    setSnack({
      message: message,
      severity: severity
    });
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    setSnackOpen(false);
  };

  const getOrders = (page) => {
    const presApi = `${process.env.REACT_APP_API_URL}/order/listvianpi`;
    const presAxiosConfig = {
      method: 'POST',
      url: presApi,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'X-CSRF-TOKEN': sessionStorage.getItem('vsncodeAdm'),
        'Pagination-Limit': 100,
        'Pagination-Page': page,
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {npi:sessionStorage.getItem('vsndocnpi')}
    };
    axios(presAxiosConfig)
      .then((response) => {
        if (response.data.code === 200) {
          //console.log(response.data);
          setPendingOrdersData(response.data.response.filter((order) => order.prescription_request_status === 'request'));
          setApprovedOrdersData(response.data.response.filter((order) => order.prescription_request_status === 'approved'));
          setRejectedOrdersData(response.data.response.filter((order) => order.prescription_request_status === 'rejected'));
        } else {
          setNoData(true);
        }
      })
      .catch((error) => {
        setNoData(true);
      });
  };

  const formatPhone = (phoneNumberString) => {
    if (phoneNumberString.length === 10) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      return phoneNumberString;
    }
  };
  
  useEffect(() => {
    getOrders(page);
  }, []);

  const calculateTimeClass = (creationTime) => {
    var currentTime = new Date();
    var createdAt = new Date(creationTime);
    var diff = (currentTime.getTime() - createdAt.getTime()) / 1000;
    var diffHours = Math.abs(Math.floor(diff / 3600));
    var diffMinutes = Math.abs(Math.floor(diff / 60));
    var remaining = diffMinutes % 60;
    var timeClassName;

    if (diffHours >= 1) {
      timeClassName = 'warning';
    } else {
      if (remaining > 15) {
        timeClassName = 'waiting';
      } else {
        timeClassName = 'normal';
      }
    }

    return timeClassName;
  };

  const calculateTime = (creationTime) => {
    var currentTime = new Date();
    var createdAt = new Date(creationTime);
    var diff = (currentTime.getTime() - createdAt.getTime()) / 1000;
    var diffHours = Math.abs(Math.floor(diff / 3600));
    var diffMinutes = Math.abs(Math.floor(diff / 60));
    var remaining = diffMinutes % 60;

    return diffHours > 0 ? `${diffHours} hrs ${remaining} mins` : `${remaining} mins`;
  };

  return (
    <Box className={classes.body}>
      <div className={classes.secondaryMenu}>
        <Tabs value={tab} onChange={handleTabChange} aria-label='Orders tabs'>
          <Tab label='Pending' />
          <Tab label='Approved' />
          <Tab label='Rejected' />
        </Tabs>
      </div>
      <TabPanel value={tab} index={0}>
        <Box className={classes.membershipInformation}>
          <Paper elevation={4}>
            <TableContainer component={Box} className={classes.tableContainer}>
              <Table size='small' className={classes.table} aria-label='orders table'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell style={{minWidth: 100}}>Phone</TableCell>
                    <TableCell>Doctor Name</TableCell>
                    <TableCell>Doctor Phone</TableCell>
                    <TableCell style={{minWidth: 280}}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingOrdersData.map((order, index) => {
                    return (
                      <TableRow key={order.id}>
                        <TableCell className={classes.firstCell}>{pendingOrdersData.length - index}</TableCell>
                        <TableCell>
                          <IconButton
                            className={classes.patientNameButton}
                            color='secondary'
                            aria-label='expand row'
                            size='small'
                            //onClick={() => handleQuesModalOpen(order.doctor_id, order.id, order.patient_id, order.prescription_request_id, order)}
                            >
                            <span className={classes.initials}>
                              {order.first_name.slice(0, 1)} {order.last_name.slice(0, 1)}
                            </span>{' '}
                            {order.first_name.charAt(0).toUpperCase() + order.first_name.slice(1)}{' '}
                            {order.last_name.charAt(0).toUpperCase() + order.last_name.slice(1)}
                          </IconButton>
                        </TableCell>
                        <TableCell>{order.email}</TableCell>
                        <TableCell>{formatPhone(order.phone)}</TableCell>
                        <TableCell>
                          {order.firstName.charAt(0).toUpperCase() + order.firstName.slice(1)}{' '}
                          {order.lastName.charAt(0).toUpperCase() + order.lastName.slice(1)}
                        </TableCell>
                        <TableCell>{formatPhone(order.dphone)}</TableCell>
                        <TableCell>
                            <Box pr={1} className={classes.actions}>
                              <Button
                                variant='outlined'
                                color='secondary'
                                onClick={() => handleApproveModalOpen(order.doctor_id, order.id, order.patient_id, order.prescription_request_id, order)}
                                startIcon={<CheckIcon style={{fill: 'green'}} />}>
                                Approve
                              </Button>
                            </Box>
                            <Box pr={1} className={classes.actions}>
                              <Button
                                variant='outlined'
                                color='secondary'
                                onClick={() => handleRejectModalOpen(order.doctor_id, order.id, order.patient_id, order.prescription_request_id)}
                                startIcon={<CloseIcon style={{fill: 'red'}} />}>
                                Reject
                              </Button>
                            </Box>
                          </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Box className={classes.membershipInformation}>
          <Paper elevation={4}>
            <TableContainer component={Box} className={classes.tableContainer}>
              <Table size='small' className={classes.table} aria-label='orders table'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell style={{minWidth: 100}}>Phone</TableCell>
                    <TableCell>Doctor Name</TableCell>
                    <TableCell>Doctor Phone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvedOrdersData.map((order, index) => {
                    return (
                      <TableRow key={order.id}>
                        <TableCell className={classes.firstCell}>{approvedOrdersData.length - index}</TableCell>
                        <TableCell>
                          <IconButton
                            className={classes.patientNameButton}
                            color='secondary'
                            aria-label='expand row'
                            size='small'
                            onClick={() => handleQuesModalOpen(order.doctor_id, order.id, order.patient_id, order.prescription_request_id, order)}>
                            <span className={classes.initials}>
                              {order.first_name.slice(0, 1)} {order.last_name.slice(0, 1)}
                            </span>{' '}
                            {order.first_name.charAt(0).toUpperCase() + order.first_name.slice(1)}{' '}
                            {order.last_name.charAt(0).toUpperCase() + order.last_name.slice(1)}
                          </IconButton>
                        </TableCell>
                        <TableCell>{order.email}</TableCell>
                        <TableCell>{formatPhone(order.phone)}</TableCell>
                        <TableCell>
                          {order.firstName.charAt(0).toUpperCase() + order.firstName.slice(1)}{' '}
                          {order.lastName.charAt(0).toUpperCase() + order.lastName.slice(1)}
                        </TableCell>
                        <TableCell>{formatPhone(order.dphone)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Box className={classes.membershipInformation}>
          <Paper elevation={4}>
            <TableContainer component={Box} className={classes.tableContainer}>
              <Table size='small' className={classes.table} aria-label='orders table'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell style={{minWidth: 100}}>Phone</TableCell>
                    <TableCell>Doctor Name</TableCell>
                    <TableCell>Doctor Phone</TableCell>
                    <TableCell style={{minWidth: 280}}>Reason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rejectedOrdersData.map((order, index) => {
                    return (
                      <TableRow key={order.id}>
                        <TableCell className={classes.firstCell}>{rejectedOrdersData.length - index}</TableCell>
                        <TableCell>
                          <IconButton
                            className={classes.patientNameButton}
                            color='secondary'
                            aria-label='expand row'
                            size='small'
                            onClick={() => handleQuesModalOpen(order.doctor_id, order.id, order.patient_id, order.prescription_request_id, order)}>
                            <span className={classes.initials}>
                              {order.first_name.slice(0, 1)} {order.last_name.slice(0, 1)}
                            </span>{' '}
                            {order.first_name.charAt(0).toUpperCase() + order.first_name.slice(1)}{' '}
                            {order.last_name.charAt(0).toUpperCase() + order.last_name.slice(1)}
                          </IconButton>
                        </TableCell>
                        <TableCell>{order.email}</TableCell>
                        <TableCell>{formatPhone(order.phone)}</TableCell>
                        <TableCell>
                          {order.firstName.charAt(0).toUpperCase() + order.firstName.slice(1)}{' '}
                          {order.lastName.charAt(0).toUpperCase() + order.lastName.slice(1)}
                        </TableCell>
                        <TableCell>{formatPhone(order.dphone)}</TableCell>
                        <TableCell>{order.prescription_request_rejection_reason}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </TabPanel>            
      <OrderReject
        doctorId={doctorId}
        patientId={patientId}
        orderId={orderId}
        prescriptionRequestId={prescriptionRequestId}
        noteModalOpen={rejectModalOpen}
        handleNoteModalClose={() => handleRejectModalClose()}
        callback={getOrders}
        snackCallback={(message, severity) => handleSnackOpen(message, severity)}
      />
      <OrderApprove
        doctorId={doctorId}
        patientId={patientId}
        orderId={orderId}
        prescriptionRequestId={prescriptionRequestId}
        orderData={orderData}
        noteModalOpen={approveModalOpen}
        handleNoteModalClose={() => handleApproveModalClose()}
        callback={getOrders}
        snackCallback={(message, severity) => handleSnackOpen(message, severity)}
      />
      <Questionary
        doctorId={doctorId}
        patientId={patientId}
        orderId={orderId}
        orderData={orderData}
        quesModalOpen={quesModalOpen}
        handleQuesModalClose={() => handleQuesModalClose()}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>

      {noData && (
        <Paper className={classes.patientCard} elevation={4}>
          Couldn't find the order you're looking for.
        </Paper>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  body: {
    padding: '0',
    margin: '60px auto 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    background: '#efefef'
  },
  membershipInformation: {
    flex: '0 0 100%',
    '& > .MuiPaper-root': {
      height: '100%'
    }
  },
  secondaryMenu: {
    padding: '8px 16px',
    background: `linear-gradient(30deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
    fontSize: '1.3em',
    fontWeight: 700,
    lineHeight: 1,
    color: '#fff',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .Mui-selected': {
      color: '#ffbf6d'
    }
  },
  createButton: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 10,
    right: 10,
    color: '#fff'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  flex: {
    display: 'flex'
  },
  actions: {
    display: 'inline-block'
  },
  delayed: {
    background: 'rgba(255,0,0,.1)'
  },
  searchForm: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputLabel-outlined': {
      color: '#fff'
    },
    '& .MuiInputBase-root': {
      color: '#fff'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.87)'
    }
  },
  patientNameButton: {
    fontSize: '1rem',
    textAlign: 'left',
    color: '#444',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '.875rem'
    }
  },
  idIcon: {
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  searchBox: {
    '& .MuiFormControl-marginDense': {
      marginTop: 4
    }
  },
  initials: {
    padding: 6,
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    color: '#fff',
    fontSize: '.75em',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
    fontWeight: 700,
    letterSpacing: '-.1em',
    textTransform: 'uppercase'
  },
  firstCell: {
    padding: 6
  }
}));