import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import AvatarUpload from './avatarUpload';

export default function PersonalInformation(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState('');
  const {doctorList, refreshDoctorList, snackCallback} = props;
  const [profileFormValues, setProfileFormValues] = useState({
    first_name:{
      value: ( doctorList[0] !== undefined && doctorList[0].firstName !== undefined) ? doctorList[0].firstName : '',
      error:false,
      errorMessage:'You must enter a first name'
    },
    middle_name:{
      value: ( doctorList[0] !== undefined && doctorList[0].middleName !== undefined) ? doctorList[0].middleName : '',
      error:false,
      errorMessage:'You must enter a middle name'
    },
    last_name:{
      value: ( doctorList[0] !== undefined && doctorList[0].lastName !== undefined) ? doctorList[0].lastName : '',
      error:false,
      errorMessage:'You must enter a last name'
    },
    title:{
      value: ( doctorList[0] !== undefined && doctorList[0].title !== undefined) ? doctorList[0].title : '',
      error:false,
      errorMessage:'You must enter a title'
    },
    email:{
      value: ( doctorList[0] !== undefined && doctorList[0].email !== undefined) ? doctorList[0].email : '',
      error:false,
      errorMessage:'You must enter an email'
    },
    mobile:{
      value: ( doctorList[0] !== undefined && doctorList[0].phone !== undefined) ? doctorList[0].phone : '',
      error:false,
      errorMessage:'You must enter a mobile'
    }
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    setProfileFormValues({
      ...profileFormValues,
      [name]:{
        ...profileFormValues[name],
        value
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formFields = Object.keys(profileFormValues);
    let newProfileFormValues = {...profileFormValues}
    let isError = false;
    for (let index = 0; index < formFields.length; index++)
    {
      const currentField = formFields[index];
      const currentValue = profileFormValues[currentField].value;

      if(currentValue === '' || currentValue === null)
      {
        isError = true;
        newProfileFormValues = {
          ...newProfileFormValues,
          [currentField]:{
            ...newProfileFormValues[currentField],
            error:true
          }
        }
      }
    }
    setProfileFormValues(newProfileFormValues);
    if(isError === false)
    {
      updateProfileInformation();
    }
  }

  const updateProfileInformation = () => {
    const api = `${process.env.REACT_APP_API_URL}/doctor/update-profile-information`;
    const axiosConfig = {
      method: 'POST',
      url: api,
      //withCredentials: 'true',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${sessionStorage.getItem('vsncodeAdm')}`
      },
      data: {
        npi:sessionStorage.getItem('vsndocnpi'),
        first_name: profileFormValues.first_name.value,
        middle_name: profileFormValues.middle_name.value,
        last_name: profileFormValues.last_name.value,
        title: profileFormValues.title.value,
        email: profileFormValues.email.value,
        mobile: profileFormValues.mobile.value
      }
    };
    axios(axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          snackCallback('Saved successfully.', 'success');
          setErrors('');
          refreshDoctorList();
        } else {
          setErrors('There was a problem. Please try again or contact IT.');
        }
      })
      .catch((error) => {
        setErrors('There was a problem. Please try again or contact IT.');
      });
  };

  return (
  <Paper elevation={4}>
    <Box className={classes.jss366}>
      <Typography variant='h4' component='h4' className={classes.fontSizeSecondary}>
        Personal Information
      </Typography>
      <Grid container spacing={1} className={classes.marginTop}>
          <Grid item xs={12} className={classes.customPadding}>
            <AvatarUpload 
              doctorList={doctorList}
              refreshDoctorList={refreshDoctorList}
              snackCallback={snackCallback} 
            />
          </Grid>
      </Grid>
      <Box className={classes.errorBox}>
        <small className={classes.error}>{errors}</small>
      </Box>
      <form noValidate onSubmit={handleSubmit} >
        <Grid container spacing={1} className={classes.marginTop}>
          <Grid item xs={4} className={classes.customPadding}>
            <Box className={classes.fullwidth}>
              <TextField
                className={[classes.fullwidth, classes.inputstyle]}
                id="outlined-first-name"
                name="first_name"
                label="First name"
                variant="outlined"
                size="small"
                focused
                required
                value={profileFormValues.first_name.value}
                onChange={handleChange}
                error={profileFormValues.first_name.error}
                helperText={profileFormValues.first_name.error && profileFormValues.first_name.errorMessage}
              />
            </Box>
          </Grid>
          <Grid item xs={3} className={classes.customPadding}>
            <Box className={classes.fullwidth}>
              <TextField
                className={classes.fullwidth}
                id="outlined-middle-name"
                name="middle_name"
                label="Middle"
                variant="outlined"
                size="small"
                focused
                value={profileFormValues.middle_name.value}
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item xs={5} className={classes.customPadding}>
            <Box className={classes.fullwidth}>
              <TextField
                className={classes.fullwidth}
                id="outlined-last-name"
                name="last_name"
                label="Last name"
                variant="outlined"
                size="small"
                focused
                required
                value={profileFormValues.last_name.value}
                onChange={handleChange}
                error={profileFormValues.last_name.error}
                helperText={profileFormValues.last_name.error && profileFormValues.last_name.errorMessage}
              />
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.customPadding}>
            <Box className={classes.fullwidth}>
              <TextField
                className={classes.fullwidth}
                id="outlined-title"
                name="title"
                label="Title"
                variant="outlined"
                size="small"
                focused
                required
                value={profileFormValues.title.value}
                onChange={handleChange}
                error={profileFormValues.title.error}
                helperText={profileFormValues.title.error && profileFormValues.title.errorMessage}
              />
            </Box>
          </Grid>
          <Grid item xs={5} className={classes.customPadding}>
            <Box className={classes.fullwidth}>
              <TextField
                className={classes.fullwidth}
                id="outlined-email"
                name="email"
                label="Email"
                variant="outlined"
                size="small"
                focused
                required
                value={profileFormValues.email.value}
                onChange={handleChange}
                error={profileFormValues.email.error}
                helperText={profileFormValues.email.error && profileFormValues.email.errorMessage}
              />
            </Box>
          </Grid>
          <Grid item xs={5} className={classes.customPadding}>
            <Box className={classes.fullwidth}>
              <TextField
                className={classes.fullwidth}
                id="outlined-mobile"
                name="mobile"
                label="Mobile"
                variant="outlined"
                size="small"
                focused
                required
                value={profileFormValues.mobile.value}
                onChange={handleChange}
                error={profileFormValues.mobile.error}
                helperText={profileFormValues.mobile.error && profileFormValues.mobile.errorMessage}
              />
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.customPadding}>
            <Button type="submit" variant="outlined" className={classes.containedSecondary}>Update</Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  
  jss360: {
    margin: "30px auto",
    maxWidth: "960px",
    width: "100%"
  },
  jss366: {
    padding: "24px"
  },
  jss388: {
    margin: "30px auto",
    maxWidth: "720px"
  },
  colorSecondary: {
    color: "#969696"
  },
  fontSizeHead: {
    fontSize: "1.5rem",
    color: "#969696",
  },
  fontSizeSecondary: {
    fontSize: "1rem",
    color: "#E1A87E",
    lineHeight: 1.5,
    marginBottom: "1rem"
  },
  marginTop: {
    marginTop: "1rem"
  },
  MuiSvgIconColorPrimary: {
    verticalAlign: "bottom"
  },
  fullwidth: {
    width: "100%"
   },
   customPadding: {
    padding: "8px 4px !important"
   },
   containedSecondary: {
    color: "#fff",
    backgroundColor: "#696969 !important"
  },
  inputstyle: {
    borderWidth: "1px !important",
    borderColor: "rgba(0, 0, 0, 0.23) !important"
  },
  error: {
    display: 'block',
    textAlign: 'center',
    marginTop: 8,
    color: 'red',
    flexBasis: '100%'
  },
  errorBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0'
  },
}));