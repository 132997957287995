import React, {useContext, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {ReactComponent as LogoMark} from '../assets/visionology-logo-mark.svg';
import {ReactComponent as Logo} from '../assets/visionology-logo.svg';
import UserContext from '../context/userContext';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {useAuth0} from '@auth0/auth0-react';

export default function Header(props) {
  const classes = useStyles();
  //const {user} = useAuth0();
  //const {logout} = useAuth0();

  sessionStorage.setItem('vsndocnpi', '1427056993');

  const navigation = [
    /*{
      text: 'Patients',
      link: '/patients',
      icon: <PersonIcon color='secondary' className={classes.mobileNav} />
    },*/
    {
      text: 'Dashboard',
      link: '/dashboard',
      icon: <PersonIcon color='secondary' className={classes.mobileNav} />
    },
    {
      text: 'Orders',
      link: '/orders',
      icon: <ShoppingCartIcon color='secondary' className={classes.mobileNav} />
    },
    {
      text: 'Balance',
      link: '/balance',
      icon: <ReceiptIcon color='secondary' className={classes.mobileNav} />
    },
    {
      text: 'Settings',
      link: '/setting',
      icon: <LocalHospitalIcon color='secondary' className={classes.mobileNav} />
    },
    /*{
      text: 'Doctors',
      link: '/doctors',
      icon: <LocalHospitalIcon color='secondary' className={classes.mobileNav} />
    },
    {
      text: 'Reminders',
      link: '/reminders',
      icon: <ReceiptIcon color='secondary' className={classes.mobileNav} />
    }*/
  ];

  useEffect(() => {
    /* console.log(user); */
  });

  return (
    <Drawer anchor={'top'} open={true} variant={'permanent'} className={classes.header}>
      <div className={classes.headerLeft}>
        <div className={classes.logoContainer}>
          <LogoMark className={classes.logoMark} />
          <Logo className={classes.logo} />
        </div>
        <List className={classes.navigation}>
          {navigation.map((option, index) => (
            <NavLink key={index} to={option.link} exact activeClassName='navigationItemActive' className={classes.navLink}>
              <ListItem button className={classes.navLinkButtons}>
                <ListItemText primary={option.text} />
              </ListItem>
              {option.icon}
            </NavLink>
          ))}
        </List>
      </div>
      <div className={classes.profileContainer}>
        {/*<Button color='secondary' onClick={() => logout({returnTo: window.location.origin})}>
          Log Out
        </Button>*/}
      </div>
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    '& .MuiPaper-root': {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 60
    }
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center'
  },
  logoContainer: {
    padding: 16,
    display: 'flex'
  },
  logoMark: {
    height: 24,
    marginRight: 8
  },
  logo: {
    height: 24,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  navigation: {
    display: 'flex',
    padding: 0,
    height: 60
  },
  navLink: {
    textDecoration: 'none',
    color: '#47474a',
    '&.navigationItemActive .MuiListItem-root': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    '& .MuiTypography-root': {
      fontSize: '.8rem',
      fontWeight: 700
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: 60,
      alignItems: 'center',
      justifyContent: 'center',
      borderLeft: '1px solid #ddd'
    }
  },
  navLinkButtons: {
    height: 60,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  mobileNav: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: 30,
      height: 30
    }
  },
  profileContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));
